//  Text Selection

::selection {
  background: $selection-background-color;
}
::-moz-selection {
  background: $selection-background-color;
}
::selection {
  background: $selection-background-color;
}
::-moz-selection {
  background: $selection-background-color;
}
::selection {
  background: $selection-background-color;
}
::-moz-selection {
  background: $selection-background-color;
}

//  iazzu Typo Guide
/*
h1 {
  font-weight: $font-weight-extrabold; // 800
  font-size: 50px;
  line-height: 55px;
  letter-spacing: 0.2em;
  font-kerning: none;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

h2 {
  font-weight: $font-weight-extrabold; // 800
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.2em;
  font-kerning: none;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

h3 {
  font-weight: $font-weight-extrabold; // 800
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.1em;
  font-kerning: none;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

h4 {
  font-weight: $font-weight-semibold; // 600
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.1em;
  font-kerning: none;
  padding: 0;
  margin: 0;
}

h5 {
  font-weight: $font-weight-extrabold; // 800
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.2em;
  font-kerning: none;
  padding: 0;
  margin: 0;
}

h6 {
  font-weight: $font-weight-extrabold; // 800
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.2em;
  font-kerning: none;
  padding: 0;
  margin: 0;
}

p {
  font-weight: $font-weight-regular; // 400
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.1em;
  font-kerning: none;
}

p > strong,
p > b {
  font-weight: $font-weight-semibold; // 600
}
*/

span.label {
}

$iazzu-color-gallery: #ebebeb;
$iazzu-color-westar: #e3ded9;
$iazzu-color-siccoro: #778180;
$iazzu-color-bison-hide: #c4b7ab;
$iazzu-color-cod-gray: #1e1e1e;
// $iazzu-color-cod-gray: #1E1E1E;
