@import "variables";
@import "fonts";
@import "mediaqueries";
@import "mixins";
@import "typoguide";
@import "masonry";

body {
  font-family: $font-default;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: white;
  color: #1e1e1e;
}

.no-text-decoration {
  text-decoration: none !important;
}

@import "./nodemodules/react-slick.scss";
@import "./nodemodules/video-react.scss";
