$font-default: "Raleway";
$font-numerals: "Rawline";

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

@font-face {
  // light
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-light-webfont.eot");
  src: url("fonts/Raleway/raleway-light-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-light-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-light-webfont.woff") format("woff"), url("fonts/Raleway/raleway-light-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-light-webfont.svg#ralewaylight") format("svg");
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  // light italic
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-lightitalic-webfont.eot");
  src: url("fonts/Raleway/raleway-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-lightitalic-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-lightitalic-webfont.woff") format("woff"), url("fonts/Raleway/raleway-lightitalic-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-lightitalic-webfont.svg#ralewaylight_italic") format("svg");
  font-weight: $font-weight-light;
  font-style: italic;
}

@font-face {
  // regular
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-regular-webfont.eot");
  src: url("fonts/Raleway/raleway-regular-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-regular-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-regular-webfont.woff") format("woff"), url("fonts/Raleway/raleway-regular-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-regular-webfont.svg#ralewayregular") format("svg");
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  // regular italic
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-italic-webfont.eot");
  src: url("fonts/Raleway/raleway-italic-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-italic-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-italic-webfont.woff") format("woff"), url("fonts/Raleway/raleway-italic-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-italic-webfont.svg#ralewayitalic") format("svg");
  font-weight: $font-weight-regular;
  font-style: italic;
}

@font-face {
  // medium
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-medium-webfont.eot");
  src: url("fonts/Raleway/raleway-medium-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-medium-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-medium-webfont.woff") format("woff"), url("fonts/Raleway/raleway-medium-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-medium-webfont.svg#ralewaymedium") format("svg");
  font-weight: $font-weight-medium;
  font-style: normal;
}

@font-face {
  // medium italic
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-mediumitalic-webfont.eot");
  src: url("fonts/Raleway/raleway-mediumitalic-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-mediumitalic-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-mediumitalic-webfont.woff") format("woff"), url("fonts/Raleway/raleway-mediumitalic-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-mediumitalic-webfont.svg#ralewaymedium_italic") format("svg");
  font-weight: $font-weight-medium;
  font-style: italic;
}

@font-face {
  // semibold
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-semibold-webfont.eot");
  src: url("fonts/Raleway/raleway-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-semibold-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-semibold-webfont.woff") format("woff"), url("fonts/Raleway/raleway-semibold-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-semibold-webfont.svg#ralewaysemibold") format("svg");
  font-weight: $font-weight-semibold;
  font-style: normal;
}

@font-face {
  // semibold italic
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-semibolditalic-webfont.eot");
  src: url("fonts/Raleway/raleway-semibolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-semibolditalic-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-semibolditalic-webfont.woff") format("woff"), url("fonts/Raleway/raleway-semibolditalic-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-semibolditalic-webfont.svg#ralewaysemibold_italic") format("svg");
  font-weight: $font-weight-semibold;
  font-style: italic;
}

@font-face {
  // bold
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-bold-webfont.eot");
  src: url("fonts/Raleway/raleway-bold-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-bold-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-bold-webfont.woff") format("woff"), url("fonts/Raleway/raleway-bold-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-bold-webfont.svg#ralewaybold") format("svg");
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  // bold italic
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-bolditalic-webfont.eot");
  src: url("fonts/Raleway/raleway-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-bolditalic-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-bolditalic-webfont.woff") format("woff"), url("fonts/Raleway/raleway-bolditalic-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-bolditalic-webfont.svg#ralewaybold_italic") format("svg");
  font-weight: $font-weight-bold;
  font-style: italic;
}

@font-face {
  // extrabold
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-extrabold-webfont.eot");
  src: url("fonts/Raleway/raleway-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-extrabold-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-extrabold-webfont.woff") format("woff"), url("fonts/Raleway/raleway-extrabold-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-extrabold-webfont.svg#ralewayextrabold") format("svg");
  font-weight: $font-weight-extrabold;
  font-style: normal;
}

@font-face {
  // extrabold italic
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-extrabolditalic-webfont.eot");
  src: url("fonts/Raleway/raleway-extrabolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-extrabolditalic-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-extrabolditalic-webfont.woff") format("woff"), url("fonts/Raleway/raleway-extrabolditalic-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-extrabolditalic-webfont.svg#ralewayextrabold_italic") format("svg");
  font-weight: $font-weight-extrabold;
  font-style: italic;
}

@font-face {
  // black
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-black-webfont.eot");
  src: url("fonts/Raleway/raleway-black-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-black-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-black-webfont.woff") format("woff"), url("fonts/Raleway/raleway-black-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-black-webfont.svg#ralewayblack") format("svg");
  font-weight: $font-weight-black;
  font-style: normal;
}

@font-face {
  // black italic
  font-family: $font-default;
  src: url("fonts/Raleway/raleway-blackitalic-webfont.eot");
  src: url("fonts/Raleway/raleway-blackitalic-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway/raleway-blackitalic-webfont.woff2") format("woff2"), url("fonts/Raleway/raleway-blackitalic-webfont.woff") format("woff"), url("fonts/Raleway/raleway-blackitalic-webfont.ttf") format("truetype"), url("fonts/Raleway/raleway-blackitalic-webfont.svg#ralewayblack_italic") format("svg");
  font-weight: $font-weight-black;
  font-style: italic;
}

@font-face {
  font-family: $font-numerals;
  src: url("fonts/Raleway_Fixed_Numerals/font/rawline-800.eot");
  src: url("fonts/Raleway_Fixed_Numerals/font/rawline-800.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway_Fixed_Numerals/font/rawline-800.woff2") format("woff2"), url("fonts/Raleway_Fixed_Numerals/font/rawline-800.woff") format("woff"), url("fonts/Raleway_Fixed_Numerals/font/rawline-800.ttf") format("truetype"), url("fonts/Raleway_Fixed_Numerals/font/rawline-800.svg") format("svg");
  font-weight: $font-weight-extrabold;
  font-style: normal;
}

@font-face {
  font-family: $font-numerals;
  src: url("fonts/Raleway_Fixed_Numerals/font/rawline-300.eot");
  src: url("fonts/Raleway_Fixed_Numerals/font/rawline-300.eot?#iefix") format("embedded-opentype"), url("fonts/Raleway_Fixed_Numerals/font/rawline-300.woff2") format("woff2"), url("fonts/Raleway_Fixed_Numerals/font/rawline-300.woff") format("woff"), url("fonts/Raleway_Fixed_Numerals/font/rawline-300.ttf") format("truetype"), url("fonts/Raleway_Fixed_Numerals/font/rawline-300.svg") format("svg");
  font-weight: $font-weight-light;
  font-style: normal;
}
