
// see styles/web/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
//  these need to be the same as for 
@mixin nb-xs { 
  @media(max-width: ($screen-sm - 1)) {
    @content;
  }
}
@mixin nb-sm {
  @media(min-width: ($screen-sm)) and (max-width: ($screen-md - 1)) {
    @content;
  }
}
@mixin nb-md {
  @media(min-width: ($screen-md)) and (max-width: ($screen-lg - 1)) {
    @content;
  }
}
@mixin nb-lg {
  @media(min-width: ($screen-lg)) and (max-width: ($screen-xl - 1)) {
    @content;
  }
}
@mixin nb-xl {
  @media(min-width: $screen-xl) {
    @content;
  }
}

@mixin nb-lg-up {
  @media(min-width: $screen-lg) {
    @content;
  }
}
@mixin nb-md-up {
  @media(min-width: $screen-md) {
    @content;
  }
}
@mixin nb-md-down { // md, sm, xs
  @media(max-width: ($screen-lg - 1)) {
    @content;
  }
}
@mixin nb-sm-up {
  @media(min-width: $screen-sm) {
    @content;
  }
}
@mixin nb-sm-down {
  @media(max-width: ($screen-md - 1)) {
    @content;
  }
}


.xs-only {
  display: none;
  @include nb-xs {
    display: initial;
  }
}
.sm-only {
  display: none;
  @include nb-sm {
    display: initial;
  }
}
.md-only {
  display: none;
  @include nb-md {
    display: initial;
  }
}
.lg-only {
  display: none;
  @include nb-lg {
    display: initial;
  }
}
.xl-only {
  display: none;
  @include nb-xl {
    display: initial;
  }
}
