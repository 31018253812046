.grid-sizer-artworks,
.grid-item-artworks {
  width: 33.33%;

  @include nb-xs {
    width: 100%;
  }
  @include nb-sm {
    width: 50%;
  }
}

.grid-item-artworks-nomasonry {
  width: 100%;
  img.main {
    width: 100%;
  }
}

.grid-item-artworks-wide {
  width: 66.66%;

  @include nb-xs {
    width: 100%;
  }
  @include nb-sm {
    width: 100%;
  }
  @include nb-md {
    width: 66.6%;
  }
  @include nb-lg {
    width: 66.66%;
  }
}

.grid-item-artworks-superwide {
  width: 100%;
}

.grid-sizer-knowledgebase-articles,
.grid-item-knowledgebase-articles {
  width: 33.33%;

  @include nb-lg {
    width: 33.33%;
  }
  @include nb-md {
    width: 50%;
  }
  @include nb-sm {
    width: 50%;
  }
  @include nb-xs {
    width: 100%;
  }
}
