@import "~video-react/styles/scss/video-react";

.video-react {
  background-color: #fff !important;
}

.video-react {
  .video-react-big-play-button {
    border: 0px solid white !important;
  }
}

.video-react .video-react-poster {
  background-color: white !important;
}

.video-react {
  outline: none !important;

  *,
  ** {
    outline: none !important;
  }
}

*:focus {
  outline: none !important;
}
