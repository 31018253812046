$font-family-default: "Nunito Sans";
// $font-family-default: "AmericanTypewriter";

$selection-background-color: rgba(0, 0, 0, 0.25);

//  same as config.js
$screen-sm: 576px !default; // usually 576
$screen-md: 768px !default; // usually 768
$screen-lg: 992px !default; // usually 992
$screen-xl: 1200px !default; // usually 1200

.col-debug {
  border: 1px solid black;
  padding: 0;
  text-align: left !important;
}

$vendor-prefixes: "-webkit-", "-moz-", "-ms-", "-o-", "";

//  Header Menu & Hero

$header-padding-horizontal: 130px;
$header-padding-horizontal-lg: 100px; // not from design
$header-padding-horizontal-md: 80px; // not from design
$header-padding-horizontal-sm: 50px; // not from design
$header-padding-horizontal-xs: 30px; // not from design

$content-padding-horizontal: 190px;
$content-padding-horizontal-lg: 20px;
$content-padding-horizontal-md: 20px;
$content-padding-horizontal-sm: 20px;
$content-padding-horizontal-xs: 20px;

//  Waypoint Themes

$waypoint-theme-transition-duration: 5s; // same as in cconfig.js

//  Components

$cheadermenu-height: 80px;
$cheadermenu-item-height: 40px;

$waypoint-theme-white-background-color: #ffffff;
$waypoint-theme-lightgray-background-color: #ebebeb;
$waypoint-theme-dark-background-color: #1e1e1e;
